import React, { Fragment, useState } from 'react';
import  ReactSlider  from 'react-slider';
import './doubleSlider.css'; // Create a CSS file for styles

const DoubleSlider: React.FC<any> = ({from, to ,onChange}) => {
  
  const handleSliderChange = (newValues: [number, number]) => {
    onChange(newValues);
  };

  return (
    <Fragment>
        <div style={{ padding: '20px' }}>
      <ReactSlider
        min={0}
        max={100}
        value={[from,to]}
        onChange={handleSliderChange}
        // renderThumb={(props, state) => <div {...props} className="thumb" />}
        // renderTrack={(props) => <div {...props} className="track" />}
        renderThumb={(props, state) => {
          const { key, ...restProps } = props;
          return <div key={key} {...restProps} className="thumb">{state.valueNow}</div>;
        }}
        
        renderTrack={(props, state) => {
          const { key, ...restProps } = props;
          return <div key={key} {...restProps} className="track" />;
        }}
      />
     
    </div>
     <p>Selected Range: {from} - {to}</p>
    </Fragment>
  );
};

export default DoubleSlider;
