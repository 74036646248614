import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import { NavLink, useNavigate } from "react-router-dom";
// import { Profiles } from "../../store/interface";
import { ApiError, ImgCrop, OnImgCrop, Profiles, UpdateProfile } from "../../store/interface";
import { getUserProfile, postUserProfile, profileCoverImgUpdate } from "../../api/profile";
import Loader from "../loader/Loader";
import Swal from "sweetalert2";
import { AxiosError } from "axios";
import { AiFillDelete } from "react-icons/ai";
import { deleteAccount } from "../../api/auth";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { useAppDispatch } from "../../hooks/useRedux";
import { logout } from "../../store/auth/authSlice";
import profileImg from "../../../src/assets/images/profileweb.png";
import { FaUser } from "react-icons/fa6";
import { FaUserEdit } from "react-icons/fa";

import Resizer from "react-image-file-resizer";
import Cropper, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const initialProfile: Profiles = {
  statusCode: 0,
  data: {
    profile: {
      instituteCode: null,
      educationType: null,
      _id: "",
      coverImage: {
        url: "",
        localPath: "",
        _id: "",
      },
      firstName: null,
      lastName: null,
      bio: "",
      location: "",
      address: [],
      zone: "",
      // instituteCode: null,

      countryCode: "",
      phoneNumber: "",
      hobbies: "",
      height: null,
      weight: null,
      owner: "",
      dob: "",
      gender: "",
    },
    addressTypes: [],
    educationTypes: [],
    states: [],
  },
  message: "",
  success: false,
};


const initialUpdateProfile:UpdateProfile={
  
  firstName:null,
  lastName:null,
  hobbies:"",
  educationType:null,
  height:null,
  weight:null,
  bio:"",
  zone:"",
  instituteCode:null,
  address:[],
  dob:"",
  gender:"",
  state:"",
  city:"",
  country:"",
  pincode:0,
  addressType:""
}

const initialImgCrop:ImgCrop={
  unit:"px",
  width:500,
  height:500,
  aspect:1

}
const initialOnImgCrop:OnImgCrop={
  x:0,
  y:0,
  height:0,
  width:0,
  unit:''
}

const MIN_WIDTH = 500;
const MIN_HEIGHT =500;

const Header: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const [profile, setProfile] = useState(false);
  const [show, setShow] = useState(false);
  const [showProfilePic, setShowProfilePic] = useState(false);
  const [guardianPortaldId, setGuardianPortaldId] = useState("");

  const [idError, setIdError] = useState(false);

  const [userprofile, setUserprofile] = useState<Profiles>(initialProfile);
  const [updateProfile,setUpdateProfile] = useState<UpdateProfile>(initialUpdateProfile);
  const [loader, setLoder] = useState<boolean>(false);
  const [isReadOnly,setIsReadOnly] =useState<boolean>(true);

  //profile image update   
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>({x: 0, y: 0,unit: "px", width: 150, height: 150 });
  const [onCrop,setOnCrop] = useState<OnImgCrop>(initialOnImgCrop);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [showImgClrBtn,setShowImgClrBtn] = useState<boolean>(false);
  const [showProPicUpdatebtn,setShowProPicUpdatebtn] = useState<boolean>(false);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const fileRef =useRef<HTMLInputElement  | null>(null);
 



  const formatDate = (isoDate: string) => {
    const date = new Date(isoDate);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('user'); 
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/login');

  }

  const handleClose = () => {
    setShow(false);
    setIdError(false);
    setGuardianPortaldId("");
  };

  const handelShow = () => {
    setShow(true);
  };
  const handleGuardianPortalIdSubmit = () => {
    if (guardianPortaldId === "121") {
      // ID matched, allow user to proceed to the portal
      window.location.href = "/guardianPortal"; // Redirect to Guardian Portal page
    } else {
      // ID doesn't match, show an error message
      setIdError(true);
    }
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const Handelprofile = () => {
    if (profile === false) {
      setProfile(true);
      fetchProfile();
    } else {
      setProfile(false);
    }
    setIsReadOnly(true);
  };


  const handleUpdateProfile = (event: React.MouseEvent<HTMLButtonElement>) =>{
    event.preventDefault();
    debugger;
    const _UpdateProfileData=updateProfile;
    _UpdateProfileData.address[0].addressType=_UpdateProfileData.addressType;
    _UpdateProfileData.address[0].state=_UpdateProfileData.state;
    _UpdateProfileData.address[0].city=_UpdateProfileData.city;
    _UpdateProfileData.address[0].pincode=_UpdateProfileData.pincode;
    setUpdateProfile(_UpdateProfileData);

    postProfile();
  }


  const postProfile = async () => {
    try {
      setLoder(true);
      debugger;
      const response = await postUserProfile(updateProfile);
      debugger;
      console.log(response);
      setLoder(false);
    } catch (error) {
      debugger;
      setLoder(false);
      console.error("Error posting user profile:", error);
      let _errorMsg: string = "";
      let _errorHtml:string="";
      if (error instanceof AxiosError) {
        const _i:ApiError=error.response?.data;
        _errorMsg = _i.message;
        let a ="<ul>";
        _i.errors.map((item,index)=>{
          a=a+"<li>"+Object.values(item)+"</li>"
        });
        a=a+"</ul>";
        _errorHtml= a.length>10?a:_errorHtml;

      } else {
        _errorMsg = "Something went wrong! Try after sometime";
      }
      
      Swal.fire({
        title: "Error",
        text: _errorMsg,
        html:_errorHtml,
        icon: "error",
        position: "top",
      });
    }
  };


  const callDeleteAccount = async () => {
    try {
      setLoder(true);
      const response = await deleteAccount();
      debugger;
      setLoder(false);

      Swal.fire({
        title: "Deleted!",
        text: "Your account has been deleted.",
        icon: "success",
        confirmButtonColor: "#0d6efd",
        confirmButtonText:"OK"
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");

        }
      });

    } catch (error) {
      debugger;
      setLoder(false);
      console.error("Error deleting user account:", error);
      let _errorMsg: string = "";
      let _errorHtml:string="";
      if (error instanceof AxiosError) {
        const _i:ApiError=error.response?.data;
        _errorMsg = _i.message;
        let a ="<ul>";
        _i.errors.map((item,index)=>{
          a=a+"<li>"+Object.values(item)+"</li>"
        });
        a=a+"</ul>";
        _errorHtml= a.length>10?a:_errorHtml;

      } else {
        _errorMsg = "Something went wrong! Try after sometime";
      }

      Swal.fire({
        title: "Error",
        text: _errorMsg,
        html:_errorHtml,
        icon: "error",
        position: "top",
      });
    }
  };

  const handleHeaderMessage = (title:string,msg:string)=>{
    Swal.fire({
      title: title,
      text: msg,
      icon: "info",
      position: "top",
    });
  }


  const handleEnableEdit =()=>setIsReadOnly(false);

  const handleAccountDelete = () =>{

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#0d6efd",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteAccount();
      }
    });

  }

  const handleFormdataUpdate =(e: any): void=>{
    const { name, value } = e.target;
    if(name==="state"){
      const _val=value.split("---");
      console.log(_val);
      setUpdateProfile(prevState => ({
        ...prevState,
        [name]: _val[0],
        "zone": _val[1],
        
      }));
    }
    else{
      setUpdateProfile(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
    //console.log(updateProfile);
    
  }

  const fetchProfile = async () => {
    try {
      setLoder(true);
      const response = await getUserProfile();

      setUserprofile(response);
      console.log(response);
        
      const _UpdateProfileData:UpdateProfile=initialUpdateProfile;

      _UpdateProfileData.firstName =response.data.profile.firstName;
      _UpdateProfileData.lastName =response.data.profile.lastName;
      _UpdateProfileData.hobbies =response.data.profile.hobbies;
      _UpdateProfileData.educationType =response.data.profile.educationType;
      _UpdateProfileData.height =response.data.profile.height;
      _UpdateProfileData.weight =response.data.profile.weight;
      _UpdateProfileData.bio =response.data.profile.bio;
      _UpdateProfileData.zone =response.data.profile.zone;
      _UpdateProfileData.instituteCode =response.data.profile.instituteCode;
      _UpdateProfileData.address =response.data.profile.address;
      _UpdateProfileData.dob =response.data.profile.dob;
      _UpdateProfileData.gender =response.data.profile.gender;
      _UpdateProfileData.state =response.data.profile.address[0].state;
      _UpdateProfileData.city =response.data.profile.address[0].city;
      _UpdateProfileData.country =response.data.profile.address[0].country;
      _UpdateProfileData.pincode =response.data.profile.address[0].pincode;
      _UpdateProfileData.addressType =response.data.profile.address[0].addressType;

      setUpdateProfile(_UpdateProfileData);
      setLoder(false);
    } catch (error) {
      setLoder(false);
      console.error("Error fetching user profile:", error);
    }
  };

  const handleProfileView = () =>{
    setShowProfilePic(true);
  }

  const handleProfilePicClose = () =>{
    setShowProfilePic(false);
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debugger;
    const file = event.target.files?.[0];

    if (file) {
      if (!file.type.startsWith("image/")) {
        alert("Please select a valid image file.");
        return;
      }
      else{setShowImgClrBtn(false)}
      setImage(file);
      setShowImgClrBtn(true);
      setPreview(URL.createObjectURL(file));
      setCroppedImage(null); // Reset cropped image when selecting a new file
    }
    else{
      setShowImgClrBtn(false);
    }
  };
  const handleCropDone = () =>{
    if(window.confirm("Is it Ok?")){
      handleCropComplete(onCrop);
      setShowProPicUpdatebtn(true);

    };
  }
  const handleOnCrop = (crop: OnImgCrop) =>{
    setOnCrop({...crop});
  }

  const handleCropComplete = async (crop: OnImgCrop) => {
    if (!imageRef.current || !crop.width || !crop.height) return;

    const img = imageRef.current;

    // Use the natural image size, not the displayed size
    const scaleX = img.naturalWidth / img.width;  
    const scaleY = img.naturalHeight / img.height;  

    let cropWidth = crop.width * scaleX;
    let cropHeight = crop.height * scaleY;

    // Ensure minimum crop size
    if (cropWidth < MIN_WIDTH || cropHeight < MIN_HEIGHT) {
        const scaleFactor = Math.max(MIN_WIDTH / cropWidth, MIN_HEIGHT / cropHeight);
        cropWidth *= scaleFactor;
        cropHeight *= scaleFactor;
    }

    // Create a high-quality cropped canvas
    const canvas = document.createElement("canvas");
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext("2d");

    if (ctx) {
        ctx.drawImage(
            img,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            cropWidth,
            cropHeight
        );

        canvas.toBlob(
            (blob) => {
                if (blob) {
                    resizeImage(blob);
                }
            },
            "image/webp",
            1
        );
    }
};


  const resizeImage = (file: Blob) => {
    Resizer.imageFileResizer(
      file,
      MIN_WIDTH,
      MIN_WIDTH,
      "webp",
      100,
      0,
      (uri) => {
        if (typeof uri === "string") {
          fetch(uri)
            .then((res) => res.blob())
            .then((blob) => {
              const resizedFile = new File([blob], "cropped-image.webp", {
                type: "image/webp",
              });
              setCroppedImage(URL.createObjectURL(resizedFile));
              setImage(resizedFile);
            });
        }
      },
      "base64"
    );
  };

  const handleUpload = async () => {
    if (!image) {
      alert("Please crop the image before uploading.");
      return;
    }

    const formData = new FormData();
    formData.append("coverImage", image);

   
    try {
      setLoder(true);
      const response =  await profileCoverImgUpdate(formData);
      debugger;
      setLoder(false);

      Swal.fire({
        title: "Updated!",
        text: "Your Profile Photo Updated.",
        icon: "success",
        confirmButtonColor: "#0d6efd",
        confirmButtonText:"OK"
      });

      fetchProfile();
      handleProfilePicClose();

    } catch (error) {
      debugger;
      setLoder(false);
      console.error("Error deleting user account:", error);
      let _errorMsg: string = "";
      let _errorHtml:string="";
      if (error instanceof AxiosError) {
        const _i:ApiError=error.response?.data;
        _errorMsg = _i.message;
        let a ="<ul>";
        _i.errors.map((item,index)=>{
          a=a+"<li>"+Object.values(item)+"</li>"
        });
        a=a+"</ul>";
        _errorHtml= a.length>10?a:_errorHtml;

      } else {
        _errorMsg = "Something went wrong! Try after sometime";
      }

      Swal.fire({
        title: "Error",
        text: _errorMsg,
        html:_errorHtml,
        icon: "error",
        position: "top",
      });
    }
    
  };

  const handleClearSelection = () => {
    setImage(null);
    setPreview(null);
    setCroppedImage(null);
    if(fileRef.current){
      fileRef.current.value = "";

    }
  };



  return (
    <>
    {loader && <Loader/>}
      <div className="header-container">
        <div className="d-flex flex-wrap   text-white fs-6 bg-secondary rounded-bottom p-2 ">
          {/* Profile Icon */}
          {/* <div className="d-flex align-items-center me-auto">
            <i className="profile-circle  bi bi-person-circle"></i>
          </div> */}

          {/* Menu Items */}
          <div className="menu-wrapper">
            <div
              className={`allHeader d-flex flex-wrap gap-4 ${
                menuOpen ? "menu-open" : "d-none d-md-flex"
              }`}
            >
              <div className=" mt-1">
                <strong>
                  <NavLink className={"home"} to="/home">
                    Home
                  </NavLink>
                  {/* <a className="home " href="/new">
                    Home
                  </a> */}
                </strong>
              </div>
              <div className="mt-1">
                <strong>
                  <a className="events" onClick={()=>{handleHeaderMessage("Events","No upcomming events!")}}>
                    Events
                  </a>
                </strong>
              </div>
              <div className=" mt-1">
                <strong>
                  <NavLink className={"mentalDisorders"} to="/mentalDisorders">
                    Mental Disorders
                  </NavLink>
                  {/* <a className="mentalDisorders" href="/new">
                    Mental Disorders
                  </a>{" "} */}
                </strong>
              </div>
              <div className=" mt-1">
                <strong>
                  {/* <a className="leaderboard" href="/new">
                    Leaderboard
                  </a> */}
                  <NavLink className={"leaderboard"} to="/LeaderBoardScreennew">
                    LeaderBoard
                  </NavLink>
                </strong>
              </div>
              <div className=" mt-1">
                <strong>
                  <a className="merchandises" onClick={()=>{handleHeaderMessage("Merchandises","Comming soon!")}}>
                    Merchandises
                  </a>
                </strong>
              </div>
              <div className=" mt-1">
                <strong>
                  <a className="counsellorsAndTherapists" href="/counsellorscreen">
                    Counsellors And Therapists
                  </a>
                </strong>
              </div>
              <div className="mt-1">
                <strong>
                  <a className="institutePortal" href="/institutePortal">
                    Institute Portal
                  </a>
                </strong>
              </div>
              <div className=" mt-1">
                <strong onClick={handelShow}>
                  {/* /guardianPortal */}
                  <a className="guardianPortal" href="#!">
                    Guardian Portal
                  </a>
                </strong>
              </div>
              {show && (
                <div className="modalOverlay">
                  <div className="modal" onClick={(e) => e.stopPropagation()}>
                    <button className="modalCloseButton" onClick={handleClose}>
                      X
                    </button>
                    <h2>Enter Student ID</h2>
                    <input
                      type="text"
                      placeholder="Enter ID"
                      value={guardianPortaldId}
                      onChange={(e) => setGuardianPortaldId(e.target.value)}
                    />
                    <button
                      className="submit-btn"
                      onClick={handleGuardianPortalIdSubmit}
                    >
                      Submit
                    </button>
                    {idError && (
                      <p className="error-text">
                        Invalid ID, please try again!
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Icons */}
          <div className="d-flex align-items-center ms-auto px-4">
            {/* <i className="bookmark_new bi bi-bookmark-fill me-3"></i> */}
            <i className="search_new  bi bi-search me-3"></i>
            <i
              className="profile-circle  bi bi-person-circle"
              onClick={Handelprofile}
            ></i>
            {profile && (
              <div
                className={`profile-container ${profile ? "profile-open" : ""}`}
              >
                <div className="bg-danger">
                  <div className="profile-close-btn" onClick={Handelprofile}>
                    &times;
                  </div>
                </div>
                {userprofile.success && (
                  <div className="profile-content">
                    <div className="card">
                        <div style={{alignItems:"flex-end"}} className="d-flex justify-content-center px-5 pt-5">
                          { userprofile.data.profile.coverImage.url?
                          <img
                            
                            src={userprofile.data.profile.coverImage.url}
                            alt="Profile"
                            style={{
                              width: "150px",
                              height: "150px",
                              borderRadius: "50%",
                              objectFit: "cover",
                              position:"relative",
                              left:"22px"
                            }}
                          />
                          
                        //   :<img
                            
                        //   src={profileImg}
                        //   alt="Profile"
                        //   style={{
                        //     width: "150px",
                        //     height: "150px",
                        //     borderRadius: "50%",
                        //     objectFit: "cover",
                        //   }}
                        // />
                          :<FaUser style={{borderRadius: "50%",
                            objectFit: "cover"}} color="gray" size={150}/>
                        }
                        <button
                        className="profile-edit-btn" onClick={handleProfileView}
                        >
                        <FaUserEdit style={{paddingLeft:"4px"}} size={25} />

                        </button>
                          {/* <img
                            src={
                              userprofile.data.profile.coverImage.url
                                ? userprofile.data.profile.coverImage.url
                                : <FaUser />
                            }
                            alt="Profile"
                            style={{
                              width: "150px",
                              height: "150px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          /> */}
                        </div>
                        <div className="card-body">
                          <div className="profile-name-edit">
                            <h3>
                              { updateProfile.firstName && updateProfile.lastName
                                ? updateProfile.firstName +" "+updateProfile.lastName
                                : "Your Name"
                              }
                        
                            </h3>

                            {
                              isReadOnly &&
                              <div className="d-flex justify-content-center">
                              <button onClick={handleEnableEdit} style={{justifyContent:"center",width:"auto"}} className="btn btn-primary d-flex px-2">
                                <div className="">
                                  <i className="bi bi-pencil-square px-2"></i>
                                </div>
                                <div className="">Edit Details</div>
                              </button>
                            </div>
                            }
                            {
                              
                              <div className="d-flex justify-content-center">
                              <button onClick={handleAccountDelete}  title="Delete Account" style={{justifyContent:"center",width:"auto"}} className="btn btn-danger d-flex px-2">
                              <AiFillDelete size={21} />

                              </button>
                            </div>
                            }
                          </div>
                          <hr />

                          {!isReadOnly &&
                            <div className="edit-form animate-slide-down">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="firstName"
                                readOnly={isReadOnly}
                                value={updateProfile.firstName || ""}
                                onChange={(e) => handleFormdataUpdate(e)}
                                placeholder="Enter First Name"
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="lastName"
                                readOnly={isReadOnly}
                                className="form-control"
                                value={updateProfile.lastName||""}
                                onChange={(e) => handleFormdataUpdate(e)}
                                placeholder="Enter Last Name"
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="date"
                                name="dob"
                                readOnly={true}
                                className="form-control"
                                value={formatDate(updateProfile.dob)||""}
                                onChange={(e) => handleFormdataUpdate(e)}
                                placeholder="Enter Last Name"
                              />
                            </div>
                            <div className="form-group">
                              <select 
                              disabled={true} 
                              name="gender" defaultValue={updateProfile.gender||""} onChange={(e) => handleFormdataUpdate(e)} className="form-control select-border">
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <select 
                              disabled={isReadOnly} 
                              name="educationType" defaultValue={updateProfile.educationType||""} onChange={(e) => handleFormdataUpdate(e)} className="form-control select-border">
                                <option value="">Select Education Type</option>
                                {userprofile.data.educationTypes.map((option, index) => (
                                  <option key={index} value={option._id}>
                                    {option.educationType}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="form-group">
                              <input
                                type="number"
                                name="height"
                                readOnly={isReadOnly}
                                className="form-control"
                                value={updateProfile.height||""}
                                onChange={(e) => handleFormdataUpdate(e)}
                                placeholder="Enter Height"
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="number"
                                name="weight"
                                readOnly={isReadOnly}
                                className="form-control"
                                value={updateProfile.weight||""}
                                onChange={(e) => handleFormdataUpdate(e)}
                                placeholder="Enter Weight"
                              />
                            </div>

                            <div className="form-group">
                              <select 
                              disabled={isReadOnly} 
                              name="addressType" defaultValue={updateProfile.addressType||""} onChange={(e) => handleFormdataUpdate(e)} className="form-control select-border">
                              <option value="">Select Address Type</option>
                                {userprofile.data.addressTypes.map((option, index) => (
                                  <option key={index} value={option._id}>
                                    {option.addressType}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="form-group">
                              <select 
                              disabled={isReadOnly} 
                              name="state" defaultValue={updateProfile.state+"---"+updateProfile.zone ||""} onChange={(e) => handleFormdataUpdate(e)} className="form-control select-border">
                              <option value="">Select Your State</option>
                                {userprofile.data.states.map((option, index) => (
                                  <option key={index} value={option.state+"---"+option.zone}>
                                    {option.state}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="form-group">
                              <input
                                type="text"
                                name="zone"
                                readOnly={true}
                                className="form-control"
                                defaultValue={updateProfile.zone||""}
                                placeholder="Zone Info"
                              />
                            </div>

                            <div className="form-group">
                              <input
                                type="text"
                                name="city"
                                readOnly={isReadOnly}
                                className="form-control"
                                value={updateProfile.city||""}
                                onChange={(e) => handleFormdataUpdate(e)}
                                placeholder="Enter City"
                              />
                            </div>

                            <div className="form-group">
                              <input
                                type="text"
                                name="pincode"
                                readOnly={isReadOnly}
                                className="form-control"
                                value={updateProfile.pincode||""}
                                onChange={(e) => handleFormdataUpdate(e)}
                                placeholder="Enter Pincode"
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="country"
                                readOnly={true}
                                className="form-control"
                                defaultValue={updateProfile.country||""}
                                placeholder="Enter Country"
                              />
                            </div>

                            <div className="form-group">
                              <textarea
                                name="hobbies"
                                readOnly={isReadOnly}
                                className="form-control"
                                value={updateProfile.hobbies||""}
                                onChange={(e) => handleFormdataUpdate(e)}
                                placeholder="Enter Hobbies"
                              />
                            </div>

                            <div className="form-group">
                              <textarea
                                name="bio"
                                readOnly={isReadOnly}
                                className="form-control"
                                value={updateProfile.bio||""}
                                onChange={(e) => handleFormdataUpdate(e)}
                                placeholder="Enter Message"
                              />
                            </div>

                            <button disabled={isReadOnly} onClick={(e)=>handleUpdateProfile(e)} className="btn btn-primary">Update Profile</button>
                          </div>
                          }
                        </div>
                    </div>
                   
                  </div>
                )}
              </div>
            )}

            <div onClick={handleLogout} title="Logout" style={{marginLeft:"10px"}}>
              <RiLogoutCircleRLine size={35}  />
            </div>

            {/* Menu Toggle Button (Visible on smaller screens) */}
            <div className="d-md-none">
              <button className="btn btnlight mb-3 " onClick={toggleMenu}>
                <i className="fs-2 fw-1 bi bi-list"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
       {/* profile picture update */}
       {showProfilePic && (
          <div className="modalOverlay" onClick={handleProfilePicClose}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
              <button className="modalCloseButton" onClick={handleProfilePicClose}>
                X
              </button>
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 card-default-padding">
                  <div className="card text-center mb-3">
                    <div className="card-body">
                      <h3 className="card-title">Update Photo</h3>
                      <div className="modal-div"></div>
                      <div className="p-4">
                        <div className="col-xs-12">
                          <input className="col-12" title={image?.name} alt={image?.name} type="file" accept="image/*"  ref={fileRef} onChange={handleFileChange} />
                        </div>

                        {preview && (
                          <div className="relative mt-4">
                            
                            {!croppedImage ? (
                              <div>
                                <p>Select the 500x500 px section to crop:</p>
                                <Cropper
                                 
                                  crop={crop}
                                  onChange={(newCrop) => setCrop(newCrop)}
                                  
                                  //onComplete={handleCropComplete}
                                  onComplete = {handleOnCrop}
                                  aspect={1}
                                >
                                  <img ref={imageRef} src={preview} alt="Crop" />
                                </Cropper>
                              </div>
                            ) : (
                              <div className="mt-4">
                                <p>Preview of the cropped image:</p>
                                <div className="col-12">
                                  <img style={{
                                    objectFit:"cover",
                                    width:"100%",
                                    height:"100%"

                                  }} src={croppedImage} alt="Preview" className="w-64 h-64" />
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                            <div>
                            {showImgClrBtn && <button
                              onClick={handleClearSelection}
                              className="upload-profile-btn"
                            >
                              ✕
                            </button>}
                            {showImgClrBtn && <button
                            onClick = {handleCropDone}
                            className="upload-profile-btn"
                            >
                            Done
                            </button>}


                        {showProPicUpdatebtn && <button
                          onClick={handleUpload}
                          disabled={!image}
                          className="upload-profile-btn"
                        >
                          Upload
                        </button>}
                            </div>
                      </div>
                        
                      
                      <div style={{ paddingTop: "20px" }}></div>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default Header;